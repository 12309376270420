import React, { useEffect, useState } from 'react'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Layout from '../app/components/Layouts/Layout'
import Error404Page from '../app/components/common/Error404/Error404'
import stylesHero from '../app/components/About/Hero/Hero.module.scss'
import ProductsSlider from '../app/components/Home/ProductsSlider/ProductsSlider'
import News from '../app/components/Home/News/News'
import Steps from '../app/components/Home/Steps/Steps'
import BannerBlock from '../app/components/Home/BannerBlock/BannerBlock'
import FaqBlock from '../app/components/Home/FaqBlock/FaqBlock'
import styles from '../app/components/Home/Steps/Steps.module.scss'
import { GetServerSidePropsContext } from 'next'
import axios from 'axios'
import { VITALIVE_SERVER_URL } from '../app/constants/constants'
import { ProductDetails } from '../app/interfaces/CartContent'
import { getCustomerData } from '../app/utilities/getCustomerData'
import { CustomerData } from '../app/interfaces/CustomerCheckout'
import { useCart } from '../app/customHooks/useCart'

const Error404 = () => {
  const [productList, setProductList] = useState<ProductDetails[]>([])
  const [customer, setCustomer] = useState<CustomerData>()

  const { cartContent, setShopifyCart, removeProduct, reconciliation } =
    useCart(customer?.email)

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${VITALIVE_SERVER_URL}/product?status=active`
      )
      const productList = res.data.result
      setProductList(productList?.slice(0, 10) || [])

      const customerData = (await getCustomerData()) as CustomerData
      setCustomer(customerData)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Layout>
      <Error404Page containerScaling={stylesHero.scalingTwo} />
      <ProductsSlider
        productList={productList}
        cartContent={cartContent}
        setShopifyCart={setShopifyCart}
        removeProduct={removeProduct}
        reconciliation={reconciliation}
        containerScaling={stylesHero.scalingTwo}
        coefficientDiscount={undefined}
        errorPage={true}
      />
      <News errorPage={true} containerScaling={stylesHero.scalingTwo} />
      <Steps
        title={
          <div className={styles.mainHeadline}>
            How do you become a <b>Vitaliv Club member</b>?
          </div>
        }
        subtitle={''}
        button="More about Vitaliv Club"
        buttonLink="/vitalivclub"
        icon1="/images/aboutUs/step-icon1-1.svg"
        icon2="/images/aboutUs/step-icon2-1.svg"
        icon3="/images/aboutUs/step-icon3-1.svg"
        containerScaling={stylesHero.scalingTwo}
        clubText={true}
      />
      <BannerBlock containerScaling={stylesHero.scalingTwo} errorPage={true} />
      <FaqBlock containerScaling={stylesHero.scalingTwo} errorPage={true} />
    </Layout>
  )
}

export async function getStaticProps(ctx: GetServerSidePropsContext) {
  return {
    props: {
      ...(await serverSideTranslations(ctx.locale!, ['common'])),
      nonZendesk: true,
    },
  }
}

export default Error404
