import React, { FC } from "react";
import styles from './Error404.module.scss'
import Link from "next/link";
import cn from "classnames";

type errorProps = {
    containerScaling?: string
}

const Error404Page:FC<errorProps> = ({containerScaling}) => {

    return (
        <section className={cn(styles.container, containerScaling)}>
            <h1 className={styles.title}>Error 404</h1>
            <p className={styles.text}>Looks like the page you are looking for does not exist</p>
            <Link href='/shop'>
                <button className={styles.button}>go to shop</button>
            </Link>
            <Link href='/feedback'>
                <p className={styles.link}>Report a bug to us</p>
            </Link>
        </section>
    )
}

export default Error404Page