import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Navbar } from '../common/Navbar/Navbar'
import { Footer } from '../common/Footer/Footer'
import { FooterNew } from '../common/FooterNew/FooterNew'
import {
  TrackingPopup,
  StartUsagePopup,
  NotificationPopup,
} from '../common/Popups/NativePermissions'
import MobileMenu from "../common/MobileMenu/MobileMenu";

export interface ILayoutProps {
  children?: any
  currentPage?: any
  isWebView?: any
  notFixedHeader?: boolean
  isMobileMenu?: boolean
}

const Layout = ({ children, currentPage, isWebView, notFixedHeader, isMobileMenu }: ILayoutProps) => {
  const [shopPopups, setShowPopups] = useState(false)
  const [popupsQueue, setPopupQueue] = useState(0)

  const router = useRouter()
  const { isShowRequestForPermissions, osType } = router.query

  useEffect(() => {
    if (isWebView) {
      setShowPopups(Boolean(isShowRequestForPermissions))
    }
  }, [])

  const changeAndPermissionHandler = (
    permissionName: string,
    page?: number
  ) => {
    if (permissionName === 'redirect') {
      window.ReactNativeWebView?.postMessage(permissionName)
      setShowPopups(false)
    } else {
      window.ReactNativeWebView?.postMessage(permissionName)
      if (page) setPopupQueue(page)
    }
  }
  if (isWebView) {
    return (
      <>
        <main>{children}</main>

        {shopPopups ? (
          osType === 'ios' ? (
            <>
              {popupsQueue === 0 && (
                <TrackingPopup
                  nextPopup={changeAndPermissionHandler}
                  closePopups={setShowPopups}
                />
              )}
              {popupsQueue === 1 && (
                <NotificationPopup
                  nextPopup={changeAndPermissionHandler}
                  closePopups={setShowPopups}
                />
              )}
              {popupsQueue === 2 && (
                <StartUsagePopup
                  closePopups={setShowPopups}
                  nextPopup={changeAndPermissionHandler}
                />
              )}
            </>
          ) : (
            <StartUsagePopup
              closePopups={setShowPopups}
              nextPopup={changeAndPermissionHandler}
            />
          )
        ) : null}
      </>
    )
  }
  return (
    <>
      <Navbar currentPage={currentPage} notFixedHeader={notFixedHeader}/>
      <main>
        {children}
        {isMobileMenu && <MobileMenu currentPage={currentPage} />}
      </main>
      <FooterNew />
    </>
  )
}

export default Layout
